import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tootle-tip" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createTextVNode(_toDisplayString($props.text) + " ", 1),
    ($props.slug)
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 0,
          to: { name: 'BlogDetail', params: { slug: $props.slug } },
          target: "_blank"
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("here")
          ])),
          _: 1
        }, 8, ["to"]))
      : _createCommentVNode("", true)
  ]))
}