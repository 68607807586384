import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withKeys as _withKeys, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "answer_input_outer mt-2" }
const _hoisted_2 = {
  style: {"width":"40px"},
  class: "avtar"
}
const _hoisted_3 = { class: "add_input_area show" }
const _hoisted_4 = { class: "form-group" }
const _hoisted_5 = { class: "article_link" }
const _hoisted_6 = ["placeholder", "value"]
const _hoisted_7 = { class: "text-danger error_input" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_user_avatar = _resolveComponent("user-avatar")!
  const _component_a_button = _resolveComponent("a-button")!
  const _directive_focus = _resolveDirective("focus")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_user_avatar, { user: _ctx.user }, null, 8, ["user"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _withDirectives(_createElementVNode("input", {
            type: "text",
            class: "form-control",
            placeholder: _ctx.placeholder,
            value: _ctx.modelValue,
            onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', ($event.target as HTMLInputElement).value))),
            onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.onSubmit()), ["enter"]))
          }, null, 40, _hoisted_6), [
            [_directive_focus]
          ]),
          _createVNode(_component_a_button, {
            loading: _ctx.isLoading,
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onSubmit()))
          }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode("Submit")
            ])),
            _: 1
          }, 8, ["loading"])
        ]),
        _createElementVNode("button", {
          class: "pt-2 input-leftbtn cancelbtn cmn-accord-btn",
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onClose && _ctx.onClose(...args)))
        }, " Close "),
        _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.error), 1)
      ])
    ])
  ]))
}