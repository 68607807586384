import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/checked.svg'
import _imports_1 from '@/assets/images/cancel.svg'


const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = { class: "list-form aprove" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_user_avatar = _resolveComponent("user-avatar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_user_avatar, {
      user: _ctx.answer.users,
      showPreview: true
    }, null, 8, ["user"]),
    _createElementVNode("div", {
      class: "title",
      innerHTML: _ctx.answer.title
    }, null, 8, _hoisted_1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onValidate(_ctx.ValidationAction.Confirm)))
      }, _cache[2] || (_cache[2] = [
        _createElementVNode("img", {
          src: _imports_0,
          alt: ""
        }, null, -1)
      ])),
      _createElementVNode("button", {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onValidate(_ctx.ValidationAction.Reject)))
      }, _cache[3] || (_cache[3] = [
        _createElementVNode("img", {
          src: _imports_1,
          alt: ""
        }, null, -1)
      ]))
    ])
  ], 64))
}