
import { defineComponent } from "@vue/runtime-core";
import { PropType } from "vue";
import {
  getAnswers,
  getAnswerToValidate,
} from "@toppick/common/build/api/answer";
import GuideToolTip from "../../topic/GuideToolTip.vue";
import AnswerCard from "./AnswerCard.vue";
import AnswerSubmitInput from "./AnswerSubmitInput.vue";
import AnswerToValidate from "./AnswerToValidate.vue";
import { Answer } from "@/interfaces/topics";
import { ActionStatus } from "@/interfaces/ui";
import { getAuthToken } from "@/utils/auth";
import { UserFeatured } from "@toppick/common/build/interfaces";

const ANSWERS_LOADED_COUNT = 3;

export default defineComponent({
  emits: ["onShowAuthModal"],
  components: {
    AnswerCard,
    AnswerSubmitInput,
    GuideToolTip,
    AnswerToValidate,
  },
  props: {
    answers: {
      type: Array as PropType<Answer[]>,
      required: true,
    },
    questionId: {
      type: Number,
      required: true,
    },
    answersCount: {
      type: Number,
      required: true,
    },
    answerValidate: {
      type: Object as PropType<Answer>,
      required: false,
    },
    showTranslation: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      isShowAddAnswer: false,
      isShowValidate: true,
      isLoading: false,
      isLoadingMoreAnswers: false,
      isAnswerToValidateLoading: false,
      answerIcon: require("@/assets/images/article-icon-orange.svg"),
      addIcon: require("@/assets/images/add-icon.svg"),
      currentAnswers: [] as Answer[],
      currentAnswersCount: this.answersCount,
      answerToValidate: this.answerValidate as Answer | null,
    };
  },
  methods: {
    toggleAddAnswer() {
      if (!this.$store.getters.getIsAuthenticated) {
        this.$emit("onShowAuthModal");
        return;
      }
      this.isShowAddAnswer = !this.isShowAddAnswer;
    },
    toggleIsShowValidate() {
      this.isShowValidate = !this.isShowValidate;
    },
    async loadAnswerToValidate() {
      this.answerToValidate = null;
      this.isAnswerToValidateLoading = true;
      if (this.$store.getters.getIsAuthenticated) {
        try {
          this.answerToValidate = await getAnswerToValidate(
            await getAuthToken(),
            {
              question_id: this.questionId,
            }
          );
        } catch (error) {
          console.log(error);
        }
      }
      this.isAnswerToValidateLoading = false;
    },

    onAddAnswer(answer: Answer) {
      answer.action_status = ActionStatus.ADDED;
      this.currentAnswers.push({ ...answer, users: this.currentUser });
      this.isShowAddAnswer = false;
    },
    onDeleteAnswer(id: number) {
      const newAnswers = this.currentAnswers.filter(
        (answer) => answer.id !== id
      );
      this.currentAnswers = newAnswers;
      this.currentAnswersCount = this.currentAnswersCount - 1;
    },

    async loadMoreAnswers() {
      if (this.isLoadingMoreAnswers) return;
      this.isLoadingMoreAnswers = true;
      const newAnswers = await getAnswers(await getAuthToken(), {
        question_id: this.questionId,
        limit: ANSWERS_LOADED_COUNT,
        skip: this.currentAnswers.length,
      });
      this.currentAnswers = [...this.currentAnswers, ...newAnswers];
      this.isLoadingMoreAnswers = false;
    },
  },
  computed: {
    currentUser() {
      return this.$store.getters.getUserFeatured;
    },
    isShowEmptyBox() {
      return (
        this.currentAnswers.length <= 0 &&
        !this.answerToValidate &&
        !this.isShowAddAnswer &&
        !this.isAnswerToValidateLoading
      );
    },
    isShowAnswerToValidate() {
      return (
        this.$store.getters.getIsAuthenticated &&
        (!!this.answerToValidate || this.isAnswerToValidateLoading)
      );
    },
    isShowLoadMore() {
      return (
        !this.isShowAddAnswer &&
        this.currentAnswers.length < this.currentAnswersCount
      );
    },
  },
  mounted() {
    this.$watch(
      () => this.answers,
      () => {
        this.currentAnswers = this.answers;
      },
      { immediate: true }
    );
  },
});
