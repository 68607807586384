import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/checked.svg'
import _imports_1 from '@/assets/images/cancel.svg'


const _hoisted_1 = { class: "myarticle" }
const _hoisted_2 = { class: "media approved_article" }
const _hoisted_3 = { class: "aticle_image" }
const _hoisted_4 = ["href"]
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "media-body" }
const _hoisted_7 = { class: "media_head" }
const _hoisted_8 = ["href"]
const _hoisted_9 = { class: "mt-0" }
const _hoisted_10 = { class: "list-form aprove" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_user_avatar = _resolveComponent("user-avatar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("a", {
          href: _ctx.resource.url,
          target: "_blank"
        }, [
          _createElementVNode("img", {
            class: "mr-3",
            src: _ctx.resource.thumbnail,
            alt: ""
          }, null, 8, _hoisted_5)
        ], 8, _hoisted_4)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_user_avatar, {
            user: _ctx.resource.users,
            showPreview: true
          }, null, 8, ["user"]),
          _createElementVNode("a", {
            href: _ctx.resource.url,
            target: "_blank",
            ref: "noopener nofollow"
          }, [
            _createElementVNode("h5", _hoisted_9, _toDisplayString(_ctx.resource.title), 1)
          ], 8, _hoisted_8)
        ]),
        _createElementVNode("p", null, _toDisplayString(_ctx.resource.snippet), 1)
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onValidate(_ctx.ValidationAction.Confirm)))
        }, _cache[2] || (_cache[2] = [
          _createElementVNode("img", {
            src: _imports_0,
            alt: ""
          }, null, -1)
        ])),
        _createElementVNode("button", {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onValidate(_ctx.ValidationAction.Reject)))
        }, _cache[3] || (_cache[3] = [
          _createElementVNode("img", {
            src: _imports_1,
            alt: ""
          }, null, -1)
        ]))
      ])
    ])
  ]))
}