
import { UserFeatured } from "@toppick/common/build/interfaces";
import { defineComponent, PropType } from "vue";
import UserAvatar from "../ui/UserAvatar.vue";
import Editor from "../ui/Editor.vue";
import CharCounter from "../ui/CharCounter.vue";

export default defineComponent({
  components: { UserAvatar, Editor, CharCounter },
  emits: ["onSubmit", "onClose", "update:modelValue"],
  props: {
    error: String,
    placeholder: String,
    modelValue: {
      type: String,
      default: "",
    },
    isLoading: Boolean,
    user: {
      required: true,
      type: Object as PropType<UserFeatured>,
    },
  },
  data() {
    return {
      value: "",
    };
  },
  methods: {
    onSubmit() {
      this.$emit("onSubmit");
    },
    onClose() {
      this.$emit("onClose");
    },
  },
});
