import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/images/pencil.svg'


const _hoisted_1 = {
  key: 1,
  class: "exp_inputArea edit_answer"
}
const _hoisted_2 = { class: "accord_btn_list_outer" }
const _hoisted_3 = { class: "text-danger error_input" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  key: 3,
  class: "accord-item-edit"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_user_avatar = _resolveComponent("user-avatar")!
  const _component_editor = _resolveComponent("editor")!
  const _component_char_counter = _resolveComponent("char-counter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showAvatar && _ctx.user)
      ? (_openBlock(), _createBlock(_component_user_avatar, {
          key: 0,
          user: _ctx.user,
          showPreview: true,
          showDefaultImage: _ctx.showAvatarDefaultImage
        }, null, 8, ["user", "showDefaultImage"]))
      : _createCommentVNode("", true),
    (_ctx.isEditing)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_editor, {
            placeholder: _ctx.placeholder,
            modelValue: _ctx.modelValue,
            onClick: _ctx.onClickInput,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event)))
          }, null, 8, ["placeholder", "modelValue", "onClick"]),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", {
              class: _normalizeClass(["accord_btn_list", { show: _ctx.isEditing && !_ctx.isLoading }])
            }, [
              _createElementVNode("button", {
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onClose && _ctx.onClose(...args))),
                class: "cancelbtn cmn-accord-btn"
              }, "Close"),
              (_ctx.showDelete)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onDelete && _ctx.onDelete(...args))),
                    class: "deletebtn cmn-accord-btn"
                  }, " Delete "))
                : _createCommentVNode("", true),
              _createElementVNode("button", {
                onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args))),
                class: "savebtn cmn-accord-btn"
              }, "Update"),
              _createVNode(_component_char_counter, {
                maxValue: 500,
                text: _ctx.modelValue
              }, null, 8, ["text"]),
              _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.error), 1)
            ], 2)
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: _normalizeClass('title ' + _ctx.backgroundClass)
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(['multiline-content translate']),
            innerHTML: _ctx.modelValue
          }, null, 8, _hoisted_4)
        ], 2)),
    (_ctx.editable)
      ? (_openBlock(), _createElementBlock("button", _hoisted_5, [
          (!_ctx.isEditing)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: _imports_0,
                class: "pencilEdit",
                alt: "",
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onEdit()))
              }))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}