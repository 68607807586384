import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "answer_area"
}
const _hoisted_2 = { class: "topAccord_area" }
const _hoisted_3 = {
  key: 0,
  class: "no_content"
}
const _hoisted_4 = { class: "cmn_topic_heading" }
const _hoisted_5 = { class: "cmn_topic_heading_img" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "add_btn" }
const _hoisted_8 = { class: "cmn_topic_heading_img" }
const _hoisted_9 = ["src"]
const _hoisted_10 = {
  key: 1,
  class: "answer_list"
}
const _hoisted_11 = { class: "cmn_topic_heading" }
const _hoisted_12 = { class: "cmn_topic_heading_img" }
const _hoisted_13 = ["src"]
const _hoisted_14 = {
  key: 1,
  class: "question_verify-div"
}
const _hoisted_15 = {
  key: 0,
  class: "question_verify"
}
const _hoisted_16 = { class: "add_btn" }
const _hoisted_17 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_guide_tool_tip = _resolveComponent("guide-tool-tip")!
  const _component_answer_card = _resolveComponent("answer-card")!
  const _component_answer_to_validate = _resolveComponent("answer-to-validate")!
  const _component_answer_submit_input = _resolveComponent("answer-submit-input")!

  return (!_ctx.isLoading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.isShowEmptyBox)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("p", _hoisted_4, [
                  _createElementVNode("span", _hoisted_5, [
                    _createElementVNode("img", {
                      src: _ctx.answerIcon,
                      alt: ""
                    }, null, 8, _hoisted_6),
                    _createVNode(_component_guide_tool_tip, {
                      slug: "create-a-good-resource",
                      text: "Discover more"
                    })
                  ]),
                  _cache[3] || (_cache[3] = _createTextVNode(" Answers "))
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("button", {
                    class: "cmn_topic_heading",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleAddAnswer()))
                  }, [
                    _createElementVNode("span", _hoisted_8, [
                      _createElementVNode("img", {
                        src: _ctx.addIcon,
                        alt: ""
                      }, null, 8, _hoisted_9)
                    ]),
                    _cache[4] || (_cache[4] = _createTextVNode(" Submit an answer "))
                  ])
                ])
              ]))
            : (_openBlock(), _createElementBlock("ul", _hoisted_10, [
                _createElementVNode("p", _hoisted_11, [
                  _createElementVNode("span", _hoisted_12, [
                    _createElementVNode("img", {
                      src: _ctx.answerIcon,
                      alt: ""
                    }, null, 8, _hoisted_13),
                    _createVNode(_component_guide_tool_tip, {
                      slug: "create-a-good-resource",
                      text: "Discover more"
                    })
                  ]),
                  _cache[5] || (_cache[5] = _createTextVNode(" Answers "))
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentAnswers, (answer) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: answer.id
                  }, [
                    _createVNode(_component_answer_card, {
                      id: answer.id,
                      action_status: answer.action_status,
                      title: answer.title,
                      user: answer.users || {} as UserFeatured,
                      status: answer.status,
                      questionId: _ctx.questionId,
                      created_by_creator: answer.created_by_creator,
                      showTranslation: _ctx.showTranslation,
                      onOnDelete: ($event: any) => (_ctx.onDeleteAnswer(answer.id))
                    }, null, 8, ["id", "action_status", "title", "user", "status", "questionId", "created_by_creator", "showTranslation", "onOnDelete"])
                  ]))
                }), 128)),
                (_ctx.isShowLoadMore)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      class: "see_more sairaM",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.loadMoreAnswers()))
                    }, _cache[6] || (_cache[6] = [
                      _createTextVNode(" See more "),
                      _createElementVNode("i", { class: "fa fa-angle-down ml-2" }, null, -1)
                    ])))
                  : _createCommentVNode("", true),
                (_ctx.isShowAnswerToValidate)
                  ? (_openBlock(), _createElementBlock("li", _hoisted_14, [
                      (_ctx.answerToValidate)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                            (_ctx.answerToValidate)
                              ? (_openBlock(), _createBlock(_component_answer_to_validate, {
                                  key: 0,
                                  answer: _ctx.answerToValidate,
                                  onLoadAnswer: _ctx.loadAnswerToValidate
                                }, null, 8, ["answer", "onLoadAnswer"]))
                              : _createCommentVNode("", true)
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.isShowAddAnswer)
                  ? (_openBlock(), _createBlock(_component_answer_submit_input, {
                      key: 2,
                      onOnClose: _ctx.toggleAddAnswer,
                      questionId: _ctx.questionId,
                      onOnSubmit: _ctx.onAddAnswer
                    }, null, 8, ["onOnClose", "questionId", "onOnSubmit"]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_16, [
                  (!_ctx.isShowAddAnswer)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        class: "cmn_topic_heading",
                        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.toggleAddAnswer && _ctx.toggleAddAnswer(...args)))
                      }, [
                        _createElementVNode("img", {
                          src: _ctx.addIcon,
                          alt: ""
                        }, null, 8, _hoisted_17),
                        _cache[7] || (_cache[7] = _createTextVNode(" Submit an answer "))
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]))
        ])
      ]))
    : _createCommentVNode("", true)
}